
import React from 'react';

function Partners() {
  const handleEmailClick = () => {
    window.location.href = 'mailto:info@reviveai.co?subject=Partner%20solutions';
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-8 text-center">Partner With Us</h1>
          
          <div className="mb-12">
            <p className="text-lg leading-relaxed mb-6">
              When customers see doors on their home they are 80% more likely to purchase. Our door visualizer helps suppliers and dealers drive higher conversion rates and improve customer satisfaction by eliminating the uncertainty of buying a new door.
            </p>
            <p className="text-lg leading-relaxed">
              If you are a door dealer or supplier interested in a visualizer for your business please reach out to us to learn more about our solutions.
            </p>
          </div>
          
          <div className="mt-12 text-center">
            <button 
              onClick={handleEmailClick}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg text-xl transition duration-300 transform hover:scale-105 shadow-lg"
            >
              Contact Us
            </button>
          </div>
          
          <div className="mt-16 bg-gray-100 p-8 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Partner Benefits</h2>
            <ul className="list-disc list-inside space-y-3 text-lg">
              <li>Increase conversion rates by 80%</li>
              <li>Improve customer satisfaction</li>
              <li>Reduce returns and cancellations</li>
              <li>Stand out from competitors</li>
              <li>Streamline the sales process</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
