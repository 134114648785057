import React from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  const handleDoorClick = () => {
    navigate('/door-replacement');
  };

  return (
    <div className="min-h-screen">
      <div 
        className="h-screen relative bg-cover bg-center"
        style={{ backgroundImage: `url('/homepageimage.png')` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center px-4">
            <h1 className="text-4xl sm:text-6xl md:text-7xl lg:text-8xl font-black text-white mb-6 tracking-tight">
              Revive Your Home
            </h1>
            <p className="text-xl sm:text-2xl text-gray-200 max-w-2xl mx-auto mb-8">
              Try on New Garage & Entry Doors on Your Home
            </p>
            <button
              onClick={handleDoorClick}
              className="bg-white text-gray-900 hover:bg-gray-100 font-bold py-4 px-8 rounded-full text-lg transition-all duration-300 hover:shadow-2xl"
            >
              Start Visualizing
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white py-20">
        <div className="max-w-5xl mx-auto px-6">
          <div className="text-center">
            <h2 className="text-4xl sm:text-5xl font-bold mb-6 text-gray-900">
              How Does it Work?
            </h2>
            <p className="text-xl text-gray-600 leading-relaxed">
              We use AI to let you preview new garage and entry doors on your home instantly. 
              Upload a photo and transform your entrance with just a few clicks.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;