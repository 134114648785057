import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, logout } = useAuth();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gray-900 text-white py-4">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <Link to="/" className="text-xl font-bold">
          <img src="/reviveailogo.png" alt="ReviveAI Logo" className="h-8" />
        </Link>

        {/* Mobile Menu Button */}
        <button
          onClick={toggleMenu}
          className="md:hidden text-white focus:outline-none"
          aria-label="Toggle menu"
        >
          {isMenuOpen ? (
            <FaTimes className="h-6 w-6" />
          ) : (
            <FaBars className="h-6 w-6" />
          )}
        </button>

        {/* Desktop Menu */}
        <nav className="hidden md:flex space-x-6 items-center">
          <Link to="/door-replacement" className="hover:text-gray-300">
            Door Visualizer
          </Link>
          <Link to="/ios-app" className="hover:text-gray-300">
            iOS App
          </Link>
          <Link to="/about" className="hover:text-gray-300">
            About
          </Link>
          <Link to="/partners" className="hover:text-gray-300">
            Partners
          </Link>
          {user ? (
            <button
              onClick={logout}
              className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded transition duration-300"
            >
              Logout
            </button>
          ) : (
            null // Login button removed
          )}
        </nav>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden absolute top-16 right-0 left-0 bg-gray-800 p-4 shadow-lg z-50">
            <nav className="flex flex-col space-y-4">
              <Link
                to="/door-replacement"
                className="hover:text-gray-300"
                onClick={toggleMenu}
              >
                Door Visualizer
              </Link>
              <Link
                to="/ios-app"
                className="hover:text-gray-300"
                onClick={toggleMenu}
              >
                iOS App
              </Link>
              <Link
                to="/about"
                className="hover:text-gray-300"
                onClick={toggleMenu}
              >
                About
              </Link>
              <Link
                to="/partners"
                className="hover:text-gray-300"
                onClick={toggleMenu}
              >
                Partners
              </Link>
              {user ? (
                <button
                  onClick={() => {
                    logout();
                    toggleMenu();
                  }}
                  className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded transition duration-300"
                >
                  Logout
                </button>
              ) : (
                null //Login button removed
              )}
            </nav>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;