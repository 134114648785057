import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Home from './pages/Home';
import DoorReplacement from './pages/DoorReplacement';
import IosApp from './pages/IosApp';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './pages/About';
import Partners from './pages/Partners'; //Import the new Partners component
import './App.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="flex flex-col min-h-screen bg-gray-900"> {/* Added background color */}
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/door-replacement" element={<DoorReplacement />} />
              <Route path="/ios-app" element={<IosApp />} />
              <Route path="/about" element={<About />} />
              <Route path="/partners" element={<Partners />} /> {/* Added Partners route */}
              {/* Redirect any other paths to home */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;