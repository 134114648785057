
import React from 'react';

function IosApp() {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-8 text-center">Garage Door Visualizer iOS App</h1>
          
          <div className="mb-8">
            <p className="text-lg">
              Try out the #1 garage door visualizer on the market. With the power of AI you can instantly 
              preview new garage doors on any home from a catalog of top suppliers.
            </p>
          </div>
          
          <div className="my-8 flex justify-center">
            <img 
              src="/iosscreenshots.png" 
              alt="iOS App Screenshots" 
              className="max-w-full rounded-lg shadow-lg" 
            />
          </div>
          
          <div className="mt-12 text-center">
            <a 
              href="https://apps.apple.com/us/app/door-visualizer-with-modifai/id1459977254" 
              className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg"
              target="_blank"
              rel="noopener noreferrer"
            >
              Try our iOS app
            </a>
          </div>
          
          <div className="mt-16 bg-gray-800 p-6 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Features available on iOS</h2>
            <ul className="list-disc list-inside space-y-2 text-lg">
              <li>Instantly see what new garage and entry doors look like on any home</li>
              <li>Displays standard, arched and dutch cornered doors</li>
              <li>Allows for painting of doors</li>
              <li>Get quotes from our partners</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IosApp;
