import React from 'react';

function About() {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-8 text-center">About Our Door Visualizer</h1>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
            <p className="text-lg">
              We're dedicated to helping homeowners visualize how new doors can transform their homes. 
              Our advanced AI technology makes it easy to see what different door styles would look 
              like on your home before making any purchases.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">How It Works</h2>
            <ol className="list-decimal list-inside space-y-2 text-lg">
              <li>Upload a photo of your home's exterior</li>
              <li>Choose the type of door you want to visualize (garage or entry)</li>
              <li>Browse through our selection of door styles</li>
              <li>Save or download your favorite transformations</li>
            </ol>
          </div>

          <div className="bg-gray-800 p-6 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="text-lg">
              Have questions or feedback? We'd love to hear from you!
              Please email us at <a href="mailto:info@reviveai.co" className="text-blue-400 hover:underline">info@reviveai.co</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;